<template>
  <GModal name="modal" class="modal modal-point-list">
    <div slot="header">
      <div class="modal-title">
        <span class="text-red">Tukar SOCO Points</span>
      </div>
    </div>
    <div slot="body">
      <div class="point-list-wrapper">
        <div
          v-for="(point, i) in pointList || []"
          :key="`${i}-${point}`"
          class="point-list-wrapper__point"
          :class="{
            active: selectActivePoint === i || activePoint === i,
            disabled: !point.is_active,
          }"
          @click="handleActivePoint(i, point)"
        >
          <div :key="`${i}-${point}`" class="detail">
            <img src="/static/img/icons/icon-soco-points.svg" alt="Icon SP" />
            <span>{{ point.points }}</span>
          </div>
        </div>
      </div>

      <div class="point-actions">
        <div class="point-actions__redeem" :class="{ disabled: activePoint === -1 }" @click="selectActivePoint">
          <span>Tukar Point</span>
        </div>
        <div class="point-actions__cancel" @click="cancel">
          <span>Batalkan</span>
        </div>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalPointList',
  props: {
    pointList: {
      type: Array,
      default: () => [],
    },
    selectedPointIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      activePoint: -1,
    };
  },

  computed: {
    existCartId() {
      return this.$store.state.QRcode.latest_cart_id;
    },
    selected_point() {
      return this.$store.state.QRcode.selected_point;
    },
  },

  mounted() {
    if (this.selected_point && this.selected_point > 0) {
      this.activePoint = this.selected_point;
    } else if (this.selectedPointIndex > -1) {
      this.activePoint = this.selectedPointIndex;
    }
  },

  methods: {
    handleActivePoint(i, { points, is_active }) {
      if (!is_active) {
        return;
      }

      this.activePoint = i;
      this.$emit('active-point', { index: i, point: points });
    },
    selectActivePoint() {
      const findPoint = this.pointList.find((p, i) => i === this.activePoint);

      if (findPoint) {
        this.$emit('selected-point', {
          point: findPoint.points,
          index: this.activePoint,
          cart_id: this.existCartId,
        });

        this.$router.push({
          path: '/vmcola/soco-points',
          query: {
            point: findPoint.points,
            cart_id: this.existCartId,
          },
        });

        this.$emit('close', true);
      }
    },
    cancel() {
      this.$emit('close', false);
    },
  },
};
</script>
